import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import { Jumbotron } from 'reactstrap';
import * as actions from '../../store/actions'
import { connect, useSelector } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { formatNumber } from '../../Utils';
import SvgArrow from '../shared/SvgArrow'


const ProductTable = ({ title, price, imageSrc, priceTitle, url, product }) => {
    if(product === '...' || !product && !price) return "";
    return (
        <Link to={`/products${url ? '/'+(product ? product.name : title) : ''}`} className="pricing-row d-flex justify-content-between" style={{maxWidth: '300px', margin: '0 auto'}}>
            <div>
                <span className="d-none">Preis Reinigung/Wäscherei </span>{product ? product.name : title}
            </div>
            <div>
                ab {product ? product.sumPrice : price}
            </div>
        </Link>
    )
}

const ProductCardPrice = ({ title, price, imageSrc, priceTitle, url, product }) => {
    if(product === '...' || (!product && !price)) return "";
    return (
    <Link to={`/products${url ? '/'+(product ? product.name.split(' ')[0] : title) : ''}`} class="col-sm-auto mb-2">
        <div className="card card ml-auto mr-auto"
            style={{
                width: "200px",
                borderRadius: "0",
                border: "0"
            }}>
            <img className="card-img-top" src={product ? product.imgUrl : imageSrc} alt={`Preis ${product ? product.name : title } Reinigung/Wäscherei`}
                style={{
                    width: "200px",
                    borderRadius: "0",
                    border: "0"
                }}
            />
            <div className="card-footer"
                style={{ borderRadius: "0", backgroundColor: "#acc7de", border: "0", padding: "1px" }}>
                <div className="d-flex justify-content-end"
                    style={{ border: "2px dashed #23479e", alignItems: "center", height: "70px" }}>
                    <div style={{
                        color: "#23479e",
                        height: "100%",
                        display: "table"
                    }} className="mr-auto ml-auto">
                        <div
                            style={{
                                display: "table-cell",
                                verticalAlign: "middle",
                                fontFamily: "holtzman-textured",
                                fontSize: "26px"
                            }}>
                            <span class="d-none">Preis Reinigung/Wäscherei </span>{title}
                        </div>
                    </div>
                    <div style={{
                        color: "#23479e",
                        backgroundColor: "#dedad4",
                        marginRight: "2px",
                        height: "63px",
                        display: "table"
                    }} >
                        <div style={{
                            display: "table-cell",
                            verticalAlign: "middle",
                            fontFamily: "holtzman-textured"
                        }}>
                            <div className="d-flex" style={{ paddingLeft: "8px", paddingRight: "6px", lineHeight: "1", paddingBottom: "10px" }}>
                                <span style={{ fontSize: "24px", paddingTop: "15px", marginRight: "5px"}}>ab </span>
                                <span style={{ fontSize: "40px" }}>{`${price ? price.split(".")[0] : product.sumPrice.split(".")[0] }.`}</span>
                                <div>
                                    <span style={{ fontSize: "24px", paddingTop: "7px" }}>{`${price ? price.split(".")[1] : product.sumPrice.split(".")[1] }`}</span>
                                    {
                                        priceTitle && <span style={{
                                            fontSize: "10px",
                                            whiteSpace: "nowrap",
                                            display: "block",
                                            marginTop: "-4px"
                                        }}>{`${priceTitle}`}</span>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Link>
)}

class PricesPage extends Component {

    componentDidMount() {

        const { allProducts, loading, OnGetProducts } = this.props
        if (allProducts.length === 0){
            OnGetProducts()

        }

    }

    getProductDetails(title) {
        const { allProducts } = this.props;
        if(allProducts.length == 0) return '...';
        const targetProducts = allProducts
            .filter(product => product.name.startsWith(title) && product.category.name == "Bekleidung") 
        targetProducts.forEach(product => {
            let productPrice;
            if (product.laundry) {
                if (product.laundry.perKilo) {
                    if (product.laundry.washingPerKiloPrice > 0) {
                        productPrice = product.laundry.washingPerKiloPrice
                    } else {
                        productPrice = product.laundry.washingAndIroningPerKiloPrice
                    }
                } else {
                    productPrice = product.laundry.washingAndIroningPrice
                }
            } else if (product.leather) {
                if ((product.leather.velourPrice || 0) > 0) {
                    productPrice = product.leather.velourPrice
                } else if ((product.leather.napaPrice || 0) > 0) {
                    productPrice = product.leather.napaPrice
                }
            }
            product.sumPrice = productPrice.toFixed(2); 
        });
        targetProducts.sort((a, b) => a.sumPrice - b.sumPrice) 
        return targetProducts[0];
    }

   /* getProductData() {

        const { categories, allProducts } = this.props;

        let doneCategories = [];
            let groupedProducts = allProducts.reduce((map, product) => {
                // Ensure the category exists in the map

                if(product.category.name == 'Gift Card') return map;

                if (!map[product.category.name]) {
                    map[product.category.name] = {}; // Initialize a new group for the category
                }
            
                // Group by product name within the category
                if (!map[product.category.name][product.name]) {
                    map[product.category.name][product.name] = {
                        products: [],
                        price: Infinity, // Start with a high value for comparison
                    };
                }

            
                // Add the product to the group
                map[product.category.name][product.name].products.push(product);
            
                // Update the lowest price for the group

                let productPrice;
                if (product.laundry) {
                    if (product.laundry.perKilo) {
                        if (product.laundry.washingPerKiloPrice > 0) {
                            productPrice = product.laundry.washingPerKiloPrice
                        } else {
                            productPrice = product.laundry.washingAndIroningPerKiloPrice
                        }
                    } else {
                        productPrice = product.laundry.washingAndIroningPrice
                    }
                } else if (product.leather) {
                    if ((product.leather.velourPrice || 0) > 0) {
                        productPrice = product.leather.velourPrice
                    } else if ((product.leather.napaPrice || 0) > 0) {
                        productPrice = product.leather.napaPrice
                    }
                }

                if (productPrice < map[product.category.name][product.name].price) {
                    map[product.category.name][product.name].price = productPrice;
                }
               
            
                return map;
            }, {});

            let structuredProducts = Object.entries(groupedProducts).map(([category, groups]) => ({
                category,
                groups: Object.entries(groups).map(([name, group]) => ({
                    name,
                    price: group.price,
                    products: group.products,
                })),
            }));

            return structuredProducts;
            


           


       // })
       
    }*/

    

    render() {

        const { allProducts, loading, OnGetProducts } = this.props
        let products;
        if(allProducts.length > 0){
          //  products = this.getProductData()
        }
        const bestseller = [
            { title: "HEMD", imageSrc: "/content/images/prices/rsz_hemd.jpg", url: true, product: this.getProductDetails('Hemd')},
            { title: "ANZUG", imageSrc: "/content/images/prices/rsz_hemd.jpg",  url: true, product: this.getProductDetails('Anzug')},
            { title: "HOSE", imageSrc: "/content/images/prices/rsz_hose.jpg", url: true, product: this.getProductDetails('Hose') },
            { title: "BLUSE", imageSrc: "/content/images/prices/rsz_bluse.jpg", url: true, product: this.getProductDetails('Bluse') },
            { title: "JACKE", imageSrc: "/content/images/prices/rsz_jacke.jpg", url: true, product: this.getProductDetails('Jacke') },
            { title: "KLEID", imageSrc: "/content/images/prices/rsz_kleid.jpg", url: true, product: this.getProductDetails('Kleid') },
            { title: "T-SHIRT", imageSrc: "/content/images/prices/rsz_kleid.jpg", url: true, product: this.getProductDetails('T-Shirt') }
        ]

        const women = [
            { title: "BLUSE", imageSrc: "/content/images/prices/rsz_hemd.jpg", url: true, product: this.getProductDetails('Bluse')},
            { title: "DAMENANZUG", imageSrc: "/content/images/prices/rsz_hemd.jpg",  url: true, product: this.getProductDetails('Damenanzug')},
            { title: "HOSE", imageSrc: "/content/images/prices/rsz_bluse.jpg", url: true, product: this.getProductDetails('Hose') },
            { title: "BLAZER", imageSrc: "/content/images/prices/rsz_jacke.jpg", url: true, product: this.getProductDetails('Blazer') },
            { title: "ROCK", imageSrc: "/content/images/prices/rsz_kleid.jpg", url: true, product: this.getProductDetails('Rock') },
            { title: "KLEID", imageSrc: "/content/images/prices/rsz_kleid.jpg", url: true, product: this.getProductDetails('Kleid') },
            { title: "FOULARD", imageSrc: "/content/images/prices/rsz_kleid.jpg", url: true, product: this.getProductDetails('Foulard') }
        ]

        const men = [
            { title: "HEMD", imageSrc: "/content/images/prices/rsz_hemd.jpg", url: true, product: this.getProductDetails('Hemd')},
            { title: "ANZUG (2-TEILIG)", imageSrc: "/content/images/prices/rsz_hemd.jpg",  url: true, product: this.getProductDetails('Anzug (2-Teilig)')},
            { title: "ANZUG (3-TEILIG)", imageSrc: "/content/images/prices/rsz_hemd.jpg",  url: true, product: this.getProductDetails('Anzug (3-Teilig)')},
            { title: "HOSE", imageSrc: "/content/images/prices/rsz_bluse.jpg", url: true, product: this.getProductDetails('Hose') },
            { title: "GILET", imageSrc: "/content/images/prices/rsz_jacke.jpg", url: true, product: this.getProductDetails('Gilet') },
            { title: "VESTON", imageSrc: "/content/images/prices/rsz_kleid.jpg", url: true, product: this.getProductDetails('Veston') },
            { title: "MANTEL", imageSrc: "/content/images/prices/rsz_kleid.jpg", url: true, product: this.getProductDetails('Mantel') },
            { title: "KRAWATTE", imageSrc: "/content/images/prices/rsz_kleid.jpg", url: true, product: this.getProductDetails('Krawatte') },
            { title: "FLIEGE", imageSrc: "/content/images/prices/rsz_kleid.jpg", url: true, product: this.getProductDetails('Fliege') }
        ]

        const heimtextilien = [
            { title: "DECKE", imageSrc: "/content/images/prices/rsz_decke.jpg", price: "23.00", url: true },
            { title: "KISSEN", imageSrc: "/content/images/prices/rsz_kissen.jpg", price: "25.00", url: true },
            { title: "VORHÄNGE", imageSrc: "/content/images/prices/rsz_vorhange.jpg", price: "7.50", priceTitle: "pro m2" },
            { title: "DUVET", imageSrc: "/content/images/prices/rsz_duvet.jpg", price: "35.00", url: true },
            { title: "TEPPICH", imageSrc: "/content/images/prices/rsz_teppich.jpg", price: "21.70", priceTitle: "pro m2", url: true }
        ]

        const schneiderei = [
            { title: "Knopf", imageSrc: "/content/images/prices/rsz_knopf.jpg", price: "5.00" },
            { title: "Kürzen", imageSrc: "/content/images/prices/rsz_kurzen.jpg", price: "20.00" },
            { title: "Zipp", imageSrc: "/content/images/prices/rsz_zipp.jpg", price: "35.00" },
            { title: "Enger", imageSrc: "/content/images/prices/rsz_enger.jpg", price: "35.00" }
        ]

        
        return (
            <div style={{ textAlign: 'center', marginBottom: "50px" }}>
                <MetaTags>
                    <title>Unsere attraktiven Preise | WÖS.CH</title>
                    <meta name="description" content="Hier erhälst Du eine detaillierte Übersicht unserer attraktiven Preise - Wir reinigen alle Deine Kleidungsstücke zuverlässig & schnell." />
                    <meta name="image" content="https://www.wös.ch/images/waschbrett.png" />
                    <meta itemProp="name" content="Unsere attraktiven Preise | WÖS.CH" />
                    <meta itemProp="description" content="Hier erhälst Du eine detaillierte Übersicht unserer attraktiven Preise - Wir reinigen alle Deine Kleidungsstücke zuverlässig & schnell." />
                    <meta itemProp="image" content="https://www.wös.ch/images/waschbrett.png" />
                    <meta name="og:title" content="Unsere attraktiven Preise | WÖS.CH" />
                    <meta name="og:description" content="Hier erhälst Du eine detaillierte Übersicht unserer attraktiven Preise - Wir reinigen alle Deine Kleidungsstücke zuverlässig & schnell." />
                    <meta name="og:image" content="https://www.wös.ch/images/waschbrett.png" />
                    <meta name="og:url" content="https://www.wös.ch/price" />
                </MetaTags>
                
               
                <div>
                    <div className="services-page jumbotron">
                        <h1 className="carousel-text-header h1" style={{ paddingBottom: "20px" }}>Unsere attraktiven Preise für Sie</h1>
                        <p className="carousel-text-content max-text py-10 t1">
                            Hier findest du eine Übersicht über unsere Preise für professionelle chemische Reinigung, Wäscherei und Schneiderei – zuverlässig und schnell in und um Zürich.
                            <br/>Alle Preise für die Artikel und ihre Services findest du im <Link to="/products">Sortiment</Link>.
                        </p>
                    </div>
                </div>
                <div className="container">

{products && products.length > 0 && (
    <div className="row">
        {products.map((categoryItem, categoryIndex) => (
        <div className="col-md-12 pricing-group" key={categoryIndex}>
            <h2>{categoryItem.category}</h2>
            <div className="pricing-group-entries">
                {categoryItem.groups.map((group, groupIndex) => (
                <div className="pricing-group-entry" key={groupIndex}>
                    <span>{group.name}</span><span>ab CHF {formatNumber(group.price)}</span>
                </div>
                ))}
            </div>
        </div>
        ))}
    </div>

    )}
</div>
    <Jumbotron className="m-0" style={{ backgroundColor: "white", paddingTop: "24px", paddingBottom: "32px" }}>
                    <h2 className="h2 m-0" style={{ paddingBottom: "32px" }}>Am meisten bestellt</h2>
                    <div className="row justify-content-center bestseller">
                        {
                            bestseller.map(t => (
                                <ProductCardPrice
                                    key={t.title}
                                    title={t.title}
                                    product={t.product}
                                    url={t.url}
                                    imageSrc={t.imageSrc}
                                    priceTitle={t.priceTitle} />
                            ))
                        }
                    </div>
                </Jumbotron>
                <div className="container">

                <div class="row">
                <Jumbotron className="m-0 col-sm-6 col-md-4" style={{ backgroundColor: "white", paddingTop: "24px", paddingBottom: "24px" }}>
                    <h2 className="h2 m-0" style={{ paddingBottom: "32px" }}>Herren</h2>
                    <div class="">
                        {
                            men.map(t => (
                                <ProductTable
                                    key={t.title}
                                    title={t.title}
                                    product={t.product}
                                    imageSrc={t.imageSrc}
                                    url={t.url}
                                    priceTitle={t.priceTitle} />
                            ))
                        }
                    </div>
                </Jumbotron>

                <Jumbotron className="m-0 col-sm-6 col-md-4" style={{ backgroundColor: "white", paddingTop: "24px", paddingBottom: "24px" }}>
                    <h2 className="h2 m-0" style={{ paddingBottom: "32px" }}>Damen</h2>
                    <div class="">
                        {
                            women.map(t => (
                                <ProductTable
                                    key={t.title}
                                    title={t.title}
                                    product={t.product}
                                    imageSrc={t.imageSrc}
                                    url={t.url}
                                    priceTitle={t.priceTitle} />
                            ))
                        }
                    </div>
                </Jumbotron>
                <Jumbotron className="m-0 col-sm-6 col-md-4" style={{ backgroundColor: "white", paddingTop: "24px", paddingBottom: "24px" }}>
                    <h2 className="h2 m-0" style={{ paddingBottom: "32px" }}>Schneiderei</h2>
                    <div className="">
                        {
                            schneiderei.map(t => (
                                <ProductTable
                                    key={t.title}
                                    title={t.title}
                                    price={t.price}
                                    imageSrc={t.imageSrc}
                                    priceTitle={t.priceTitle} />
                            ))
                       }
                    </div>
                </Jumbotron>
                </div>
                </div>
                <Link to="/products" className="btn-blue bold mt-5">
                        Komplettes Sortiment anzeigen <SvgArrow />
                </Link>
               
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.products.loading,
        allProducts: state.products.allProducts,
        categories: state.categories.categories
    };
};

const mapDispatchToProps = dispatch => {
    return {
        AddToShoppingCartAndGoToCart: (product) => dispatch(actions.AddToShoppingCartAndGoToCart(product)),
        AddToShoppingCart: (product, variants) => dispatch(actions.AddToShoppingCart(product, variants)),
        OnGetProducts: () => dispatch(actions.getProducts()),
        OnUpdateProducts: (products, categoryId, searchTerm) => dispatch(actions.updateProducts(products, categoryId, searchTerm))
    };
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PricesPage));
