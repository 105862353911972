import React, { Component, createRef } from 'react'
import { Container } from 'reactstrap'
import { connect } from 'react-redux';
import { Redirect } from 'react-router'
import { Link } from 'react-router-dom';
import { Row } from 'reactstrap';
import SvgArrow from '../shared/SvgArrow';
import SvgPlus from '../shared/SvgPlus';
import { getItems, checkoutPageEnabled, onlyGiftCards } from '../../store/reducers/shoppingCart';
import CartItem from './CartItem'
import * as actions from "../../store/actions";

class ShoppingCart extends Component {

    elementRef = createRef();
    placeholderRef = createRef();
    state = {
        ctaFixed: false
    };

    checkLoggedIn = e => {
        if (!this.props.token) {
            e.preventDefault();
            this.props.OnAuthModalToggle();
            // Trigger loggin modal
        }
    };

    componentDidMount() {
     document.querySelector('.content').addEventListener('scroll', this.handleScroll);
     this.checkIfInViewport();
    }

    componentDidUpdate(prevProps) {
        if(prevProps.items.length != this.props.items.length){
            this.checkIfInViewport(true);
        }
    }

    componentWillUnmount() {
        document.querySelector('.content').removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        this.checkIfInViewport();
    };
    

    checkIfInViewport = (force) => {

        if (this.placeholderRef.current) {

            const rect = this.placeholderRef.current.getBoundingClientRect();
            const isInViewport =
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth);

            this.setState({ ctaFixed: !isInViewport });
        }
    };

    render() {
        const { items, checkoutPageButtonEnabled, onlyGiftCards } = this.props;

        if (items.length === 0) {
            return <Redirect to="/products" />
        }

        return (
            <div style={{ backgroundColor: "#ECECEA", minHeight: "calc(100vh - 322px)" }}>
                <Container className="container-shopping-cart">
                    {
                        items.map((_product, index) => <CartItem key={index} itemIndex={index} />)
                    }
                    <Row>
                        <div className="button-placeholder" ref={this.placeholderRef}></div>
                        <Link to="/products" className={`left sticky-a ${this.state.ctaFixed ? 'fixed' : ''}`}>
                            <button className="bold">
                                <SvgPlus />
                                {"Weitere Artikel hinzufügen"} 
                            </button>
                        </Link>
                        <Link to="/shopping-cart/page2" ref={this.elementRef} className={`sticky-a ${this.state.ctaFixed ? 'fixed' : ''}`} onClick={this.checkLoggedIn}>
                            <button className="bold"
                                disabled={!checkoutPageButtonEnabled}>
                                {onlyGiftCards ? "Weiter zur Zahlung" : "Abholung und Lieferung wählen"} 
                                <SvgArrow />
                            </button>
                        </Link>
                    </Row>
                </Container>

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        items: getItems(state),
        token: state.auth.token,
        checkoutPageButtonEnabled: checkoutPageEnabled(state),
        onlyGiftCards: onlyGiftCards(state)
    };
};

const mapDispatchToProps = dispatch => ({
    OnAuthModalToggle: () => dispatch(actions.authModalToggle()),
});


export default connect(mapStateToProps, mapDispatchToProps)(ShoppingCart)